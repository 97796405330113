.color {
  height: 40px;
  width: 120px;
  display: inline-block; }

.maint {
  background-color: #66cc33; }

.dark {
  background-color: #004b6b; }

.light {
  background-color: #d0e2f3; }

.other {
  background-color: #7bdef5; }

.hoverable {
  cursor: pointer; }
  .hoverable:hover {
    background-color: #f5f5f5; }

h2 {
  color: #004b6b; }

.pointer {
  cursor: pointer; }

.popup.inverted a {
  color: #ccc; }
  .popup.inverted a:hover {
    text-decoration: underline;
    color: #aaa; }

.ui.sidebar {
  transform: none !important; }

.phone-only {
  display: none; }
  @media only screen and (max-width: 767px) {
    .phone-only {
      display: inherit; } }

.large-screen-only {
  display: inherit; }
  @media only screen and (max-width: 767px) {
    .large-screen-only {
      display: none; } }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 11pt;
  background-color: #1b1c1d !important; }
  body h1, body h2, body h3, body h4, body h5 {
    font-family: "Raleway", "Open Sans", sans-serif; }
  body .fullHeight {
    min-height: 100%; }

.splash .header-container {
  position: relative;
  display: block;
  height: 500px;
  height: 100vh;
  background: url("resources/images/errol-337193-small.jpg") no-repeat top center;
  background-size: cover;
  padding-top: 40px; }
  .splash .header-container .heading {
    text-align: left;
    margin: 0 auto;
    width: 100%;
    color: #fff; }
    .splash .header-container .heading img {
      float: left;
      height: 140px; }
    .splash .header-container .heading .text {
      padding-top: 40px; }
    .splash .header-container .heading h1 {
      color: #fff;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
      font-size: 24pt;
      margin: 0; }
    .splash .header-container .heading h2 {
      font-size: 14pt;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      margin: 0; }
    .splash .header-container .heading .calltoaction {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: 20px; }
      .splash .header-container .heading .calltoaction a {
        color: #fff; }
        .splash .header-container .heading .calltoaction a:hover {
          text-decoration: underline; }
  .splash .header-container .bottom-container {
    position: absolute;
    bottom: 100px;
    text-align: center;
    width: 100%; }
    .splash .header-container .bottom-container .more {
      border-radius: 20px;
      color: #fff !important;
      border: 1px solid #fff;
      background: rgba(102, 204, 51, 0.5) !important;
      box-shadow: none !important;
      -webkit-transition: background 300ms, color 300ms, border-color 300ms;
      /* Safari */
      transition: background 300ms, color 300ms, border-color 300ms; }
      .splash .header-container .bottom-container .more:hover, .splash .header-container .bottom-container .more:active {
        background: linear-gradient(135deg, #66cc33, #669933) !important; }

.global-feedback-button {
  position: fixed;
  z-index: 9999;
  left: 100px;
  bottom: 0;
  background-color: #004b6b;
  color: #fff;
  font-size: 14pt;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .global-feedback-button:hover {
    padding-bottom: 15px;
    background-color: #3d7a1f; }
  .global-feedback-button i {
    font-size: 12pt; }

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: #555; }

.padding {
  padding: 10px; }

.main-container {
  margin: 0;
  padding: 0;
  min-height: 100%;
  padding-top: 40px; }

.ui.button.inactive {
  pointer-events: none;
  cursor: default;
  font-weight: 700; }

.ui.button.no-border {
  box-shadow: none; }

img.mode-icon {
  height: 20px;
  max-width: 20px; }
  img.mode-icon.white {
    -webkit-filter: invert(100%); }
  img.mode-icon.large {
    height: 80px;
    max-width: 80px; }

.footer {
  clear: both;
  padding: 60px;
  padding-top: 0;
  margin-top: 60px;
  color: #fff; }
  .footer a {
    color: #ddd; }
    .footer a:hover {
      color: #fff;
      text-decoration: underline; }
  .footer p {
    color: #bbb; }
  .footer .social {
    float: right; }
  .footer .social img {
    height: 20px; }

.title-header {
  margin-top: 60px;
  width: 100%;
  z-index: 100;
  background-color: #96d3b8;
  height: 120px;
  color: rgba(255, 255, 255, 0.8); }
  .title-header h1 {
    font-size: 25pt;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 6px;
    margin-bottom: 2px; }
    .title-header h1 span {
      font-variant: small-caps;
      color: #fff;
      font-weight: 300;
      font-size: 18pt;
      line-height: 16pt; }
  .title-header h3 {
    margin-top: 0px;
    font-weight: 500; }
  @media only screen and (max-width: 767px) {
    .title-header {
      position: static;
      margin-top: 60px; }
      .title-header h1 {
        font-size: 18pt; }
      .title-header h3 {
        font-size: 10pt; } }

.action-buttons {
  padding-top: 20px; }
  .action-buttons .button {
    position: relative;
    float: left;
    width: 120px;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-right: 40px;
    padding: 8px 10px;
    background-color: #fff;
    cursor: pointer;
    color: #d33a37;
    font-weight: 500;
    line-height: 14pt; }
    .action-buttons .button.newsletter {
      width: 160px; }
    .action-buttons .button:hover {
      background-color: rgba(255, 255, 255, 0.9); }
    .action-buttons .button i.icon {
      display: block;
      float: left;
      margin-left: -24px !important;
      background-color: #d33a37;
      border-radius: 15px;
      height: 30px;
      width: 30px;
      line-height: 26px;
      color: #fff;
      border: 2px solid #fff; }

.welcome-container {
  color: #fff;
  font-size: 14pt;
  line-height: 20pt;
  padding-bottom: 20px;
  padding-top: 40px; }
  .welcome-container h1 {
    font-size: 26pt; }
  .welcome-container strong {
    margin-right: 6px; }
  .welcome-container a {
    color: #fff;
    text-decoration: underline; }

.container > .header-container > .header {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05); }
  .container > .header-container > .header:not(.splash) {
    top: 60px; }
    @media only screen and (max-width: 767px) {
      .container > .header-container > .header:not(.splash) {
        position: static; } }
  .container > .header-container > .header.push-down {
    top: 170px; }

.container > .header-container > .header.gt-card, .planner.gt-card {
  width: 100%;
  z-index: 101; }

.container > .header-container > .header.splash, .planner.splash {
  margin: 0 auto;
  left: 0;
  width: 100%;
  max-width: 1200px; }

.container > .header-container > .header .main, .planner .main {
  min-height: 150px;
  padding-top: 50px;
  padding-bottom: 20px;
  background: #ffffff;
  box-sizing: border-box; }

.container > .header-container > .header.gt-card .main, .planner.gt-card .main {
  min-height: 200px; }

.container > .header-container > .header.splash .main, .planner.splash .main {
  min-height: 150px;
  padding-top: 20px;
  background: #f0f0f0; }

.container > .header-container > .header .ui.red.corner.label, .planner .ui.red.corner.label {
  text-align: left; }
  .container > .header-container > .header .ui.red.corner.label > div, .planner .ui.red.corner.label > div {
    margin: 8px 6px;
    font-size: 11pt; }

.container > .header-container > .header h2.try, .planner h2.try {
  padding: 0;
  margin: 0;
  text-align: center; }

.container > .header-container > .header .try-sub, .planner .try-sub {
  text-align: center; }

.container > .header-container > .header .sub, .planner .sub {
  background: linear-gradient(326deg, #004b6b 0%, #1d89b5 100%);
  color: #fff; }
  .container > .header-container > .header .sub .date-input, .planner .sub .date-input {
    position: relative; }

.container > .header-container > .header .graphic, .planner .graphic {
  vertical-align: middle; }

.container > .header-container > .header.large .main, .planner.large .main {
  padding: 80px;
  padding-top: 60px; }

.container > .header-container > .header.large .sub, .planner.large .sub {
  display: none; }

.container > .header-container > .header.large .large-fields strong, .planner.large .large-fields strong {
  font-size: 14pt;
  display: block;
  margin-bottom: -10px; }

.container > .header-container > .header .sub, .planner .sub {
  background-color: #d0e2f3;
  height: 64px; }
  .container > .header-container > .header .sub input, .planner .sub input {
    background-color: #fff;
    padding: 14px; }
  .container > .header-container > .header .sub .mode-icons, .planner .sub .mode-icons {
    font-size: 14pt; }
    .container > .header-container > .header .sub .mode-icons .mode-icon, .planner .sub .mode-icons .mode-icon {
      display: inline-block;
      margin: 3px 6px; }
      .container > .header-container > .header .sub .mode-icons .mode-icon img.mode-icon, .planner .sub .mode-icons .mode-icon img.mode-icon {
        display: block;
        cursor: pointer;
        opacity: 0.4;
        height: 30px;
        max-width: 30px;
        filter: invert(1); }
        .container > .header-container > .header .sub .mode-icons .mode-icon img.mode-icon.active, .planner .sub .mode-icons .mode-icon img.mode-icon.active {
          opacity: 1; }
        .container > .header-container > .header .sub .mode-icons .mode-icon img.mode-icon:hover, .planner .sub .mode-icons .mode-icon img.mode-icon:hover {
          opacity: 0.7; }
      .container > .header-container > .header .sub .mode-icons .mode-icon i.small, .planner .sub .mode-icons .mode-icon i.small {
        font-size: 8pt;
        margin: 0 1px;
        -webkit-animation: pulsate 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        color: #66cc33;
        visibility: hidden; }
        .container > .header-container > .header .sub .mode-icons .mode-icon i.small.visible, .planner .sub .mode-icons .mode-icon i.small.visible {
          visibility: visible; }

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0; } }
    .container > .header-container > .header .sub .mode-icons .search-again, .planner .sub .mode-icons .search-again {
      position: absolute;
      top: 8px;
      left: 140px;
      font-size: 11pt;
      font-style: italic; }
  .container > .header-container > .header .sub .time .type, .planner .sub .time .type {
    width: 60%;
    float: left; }
    .container > .header-container > .header .sub .time .type span, .planner .sub .time .type span {
      cursor: pointer; }
    .container > .header-container > .header .sub .time .type .selected, .planner .sub .time .type .selected {
      font-weight: bold; }
  .container > .header-container > .header .sub .time .time-input, .planner .sub .time .time-input {
    width: 40%;
    float: left; }

.main-container .header-container .splash .heading {
  max-width: 1200px; }

.ui.image.rounded img {
  border-radius: 20px; }

#logo {
  width: 200px;
  margin: 20px auto;
  display: block; }

.menu .ui.red.corner.label {
  text-align: left; }
  .menu .ui.red.corner.label > div {
    margin: 8px 6px;
    font-size: 6pt; }

.menu .home {
  font-size: 12pt;
  font-weight: 200 !important; }
  .menu .home .logo {
    display: inline-block;
    margin-right: 10px !important; }
  .menu .home strong {
    font-weight: 700;
    color: #004b6b; }
  .menu .home .logo-circle {
    height: 40px;
    width: 40px;
    background-color: #66cc33;
    border-radius: 20px;
    margin-right: 10px; }

.menu.ui .right a.item.active {
  position: relative; }
  .menu.ui .right a.item.active:before {
    content: "";
    position: absolute;
    top: 0;
    height: 4px;
    width: 100%;
    background-color: #66cc33; }

.menu .action-buttons {
  margin: 0 auto;
  text-align: center;
  padding-top: 12px; }
  .menu .action-buttons .button {
    font-size: 9pt;
    line-height: 11pt;
    font-weight: 700;
    display: inline-block;
    float: none;
    width: 80px;
    border: 2px solid #d33a37;
    border-radius: 10px;
    margin-right: 40px;
    padding: 3px 4px;
    background-color: #fff;
    cursor: pointer;
    color: #d33a37; }
  .menu .action-buttons .button:last-child {
    margin-right: 0;
    width: 120px; }
  .menu .action-buttons .button:hover {
    background-color: #ffeff1; }
  @media only screen and (max-width: 767px) {
    .menu .action-buttons .button.newsletter {
      display: none; } }
  .menu .action-buttons i.icon {
    display: block;
    float: left;
    margin-left: -24px !important;
    background-color: #fff;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    line-height: 26px;
    color: #d33a37;
    border: 2px solid #d33a37; }

.ui.grid > .row.homepage-planner-form-container {
  padding-bottom: 50px; }
  .ui.grid > .row.homepage-planner-form-container .homepage-planner-form {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 10px;
    min-height: 350px; }
    .ui.grid > .row.homepage-planner-form-container .homepage-planner-form .coverage-map .title {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 8px;
      padding-right: 50px;
      font-size: 10pt; }
    .ui.grid > .row.homepage-planner-form-container .homepage-planner-form .coverage-map img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      height: 100%;
      max-height: 350px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.2); }
    @media only screen and (max-width: 991px) {
      .ui.grid > .row.homepage-planner-form-container .homepage-planner-form .route-form {
        padding-bottom: 300px; } }
  .ui.grid > .row.homepage-planner-form-container h1 {
    color: #fff;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); }

.ui.grid .route-form.ui.stackable.grid {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.route-form {
  margin: 0 auto; }
  .route-form .large-fields .first {
    text-align: right; }
  .route-form .large-fields .type span:not(.selected)::before {
    /* add the new bullet point */
    display: inline-block;
    content: '';
    margin-right: 1.25rem; }
  .route-form .large-fields .type span.selected::before {
    /* add the new bullet point */
    display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    background-color: #66cc33; }
  .time-picker-popup {
    padding: 0 !important; }
    .time-picker-popup:before {
      background-color: #004b6b !important; }
    .time-picker-popup .time-picker > div {
      height: 200px;
      width: 70px;
      overflow: auto; }
      .time-picker-popup .time-picker > div.header {
        padding-top: 6px;
        height: 26px;
        font-weight: 700;
        overflow: hidden;
        background-color: #004b6b;
        color: #fff; }
        .time-picker-popup .time-picker > div.header.hours {
          border-top-left-radius: 5px; }
        .time-picker-popup .time-picker > div.header.minutes {
          border-top-right-radius: 5px; }
      .time-picker-popup .time-picker > div.hours {
        float: left;
        text-align: right; }
      .time-picker-popup .time-picker > div.minutes {
        float: right;
        text-align: left; }
    .time-picker-popup .time-picker ul {
      list-style-type: none;
      padding: 0;
      margin: 0; }
      .time-picker-popup .time-picker ul li {
        padding: 6px 4px;
        height: 32px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        color: #333333; }
        .time-picker-popup .time-picker ul li:hover {
          background-color: #eee; }
        .time-picker-popup .time-picker ul li.selected {
          background-color: #66cc33;
          color: #004b6b;
          font-weight: 500; }
  .date-picker-popup {
    padding: 0 !important; }
    .date-picker-popup:before {
      background-color: #004b6b !important; }
    .date-picker-popup .Cal__Container__root {
      font-family: "Open Sans", sans-serif;
      border-radius: 5px;
      overflow: hidden;
      /* Make month lines more clear */ }
      .date-picker-popup .Cal__Container__root ul.Cal__Weekdays__root {
        background-color: #004b6b !important;
        height: 30px; }
        .date-picker-popup .Cal__Container__root ul.Cal__Weekdays__root li.Cal__Weekdays__day {
          padding: 6px 0;
          font-weight: 700 !important; }
      .date-picker-popup .Cal__Container__root div.Cal__Today__root.Cal__Today__show.Cal__Today__chevronDown {
        background-color: #006f9e !important;
        height: 30px; }
      .date-picker-popup .Cal__Container__root span.Cal__Day__month {
        color: #004b6b;
        font-weight: 500; }
      .date-picker-popup .Cal__Container__root div.Cal__Day__selection {
        color: #004b6b !important;
        background-color: #66cc33 !important; }
      .date-picker-popup .Cal__Container__root li.Cal__Day__root.Cal__Day__today.Cal__Day__enabled {
        color: #004b6b !important; }
      .date-picker-popup .Cal__Container__root .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
        box-shadow: 0px -1px 0 #9a9a9a, inset 1px 0 0 #9a9a9a !important; }
      .date-picker-popup .Cal__Container__root .Cal__Month__row:first-child li {
        box-shadow: 0 -1px 0 #9a9a9a !important; }
      .date-picker-popup .Cal__Container__root .Cal__Month__row:nth-child(2) {
        box-shadow: 0 -1px 0 #9a9a9a !important; }
      .date-picker-popup .Cal__Container__root div.Cal__MonthList__root::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-left: 1px solid #eee;
        width: 121px;
        height: 100%; }
  .route-form .large-fields.autocomplete > div {
    text-align: left; }
    .route-form .large-fields.autocomplete > div > div {
      position: relative;
      width: 100%; }
      .route-form .large-fields.autocomplete > div > div > div {
        position: absolute !important;
        top: auto !important;
        left: auto !important;
        max-height: none !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        z-index: 10001; }
  .route-form .large-fields.autocomplete .powered-by {
    padding: 0 6px;
    text-align: right; }
  .route-form .large-fields strong {
    color: #fff;
    padding-left: 20px; }
  .route-form .large-fields input {
    height: 40px;
    width: 100%;
    border-radius: 10px 10px 0 10px;
    border: 1px solid #66cc33;
    padding: 5px 20px;
    font-size: 11pt; }
    .route-form .large-fields input:focus {
      outline: none; }
      .route-form .large-fields input:focus.autocomplete {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
    .route-form .large-fields input.search {
      height: 40px;
      width: auto;
      background-color: #004b6b;
      border: none;
      font-size: 11pt;
      font-weight: 700;
      padding: 5px 20px;
      color: #fff;
      cursor: pointer; }
    .route-form .large-fields input:disabled {
      color: #fff;
      opacity: 0.5; }
  .route-form .large-fields .date-input input {
    padding: 5px; }
  .route-form .large-fields .spinner {
    position: absolute;
    top: 20px;
    right: -50px;
    color: #fff;
    font-size: 18pt;
    line-height: 40px; }
  .route-form .autocomplete {
    z-index: 99;
    width: 100%; }
    .route-form .autocomplete .item {
      min-height: 40px;
      cursor: pointer;
      z-index: 99;
      width: 100%;
      overflow: hidden; }
      .route-form .autocomplete .item .icon {
        display: block;
        height: 90%;
        float: left;
        opacity: 0.4; }
        .route-form .autocomplete .item .icon .svg-inline--fa {
          height: 20px;
          width: 20px; }
      .route-form .autocomplete .item span {
        font-weight: 700; }
      .route-form .autocomplete .item.highlighted {
        background-color: #66cc33; }
      .route-form .autocomplete .item .country {
        opacity: 0.5;
        float: right; }

.pac-container {
  background-color: #d0e2f3;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4); }

.pac-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.routes .filters-container {
  background-color: #fff;
  width: 450px;
  margin: 10px 40px;
  border-radius: 1px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05); }
  .routes .filters-container .sort-button {
    display: inline-block;
    padding: 10px 15px;
    margin: 5px;
    background-color: #004b6b;
    color: #fff;
    cursor: pointer;
    border-radius: 5px; }
    .routes .filters-container .sort-button.active {
      background-color: #66cc33; }

.routes .route-card {
  background-color: #fff;
  width: 450px;
  margin: 10px 40px;
  border-radius: 1px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05); }
  .routes .route-card .header {
    border-bottom: 1px solid #ccc; }
  .routes .route-card .parts {
    border-top: 1px solid #ccc; }
    .routes .route-card .parts .part-header {
      margin-top: 10px;
      font-weight: 700; }
      .routes .route-card .parts .part-header:first-letter {
        text-transform: capitalize; }
    .routes .route-card .parts .part-alternatives {
      font-size: 10pt;
      line-height: 13pt;
      color: #666;
      border-bottom: 1px solid #ccc; }
      .routes .route-card .parts .part-alternatives .alternative {
        padding-left: 10px; }
  .routes .route-card .body, .routes .route-card .parts {
    padding: 10px; }
  .routes .route-card .time {
    color: #555;
    float: right; }
  .routes .route-card .price {
    float: right; }
  .routes .route-card h3 {
    min-height: 35px;
    background-color: #f6f6f6;
    color: #66cc33;
    margin: 0 0 0;
    padding: 10px; }
    .routes .route-card h3 .right {
      text-align: right;
      float: right;
      color: #004b6b;
      font-weight: normal;
      font-size: 12pt; }
  .routes .route-card .subtitle {
    display: block;
    font-weight: 100; }

.page-container {
  padding-top: 30px;
  background-color: #fbfbfb; }

.page-container.new-style {
  background-color: #D1E1BD;
  padding-bottom: 50px;
  font-family: "Assistant", "Helvetica", "Arial", sans-serif; }
  .page-container.new-style.secondary h1, .page-container.new-style.secondary h2, .page-container.new-style.secondary h3, .page-container.new-style.secondary h4, .page-container.new-style.secondary h5 {
    color: #fff; }
  .page-container.new-style h1, .page-container.new-style h2, .page-container.new-style h3, .page-container.new-style h4 {
    font-family: "Playfair Display", serif;
    font-weight: normal; }
  .page-container.new-style .ui.form h3, .page-container.new-style .ui.form h5 {
    margin-bottom: 4px; }
  .page-container.new-style .ui.form .ui.input input, .page-container.new-style .ui.form .field textarea, .page-container.new-style .ui.form input.autocomplete {
    border: 2px solid #82AF4A;
    background-color: #D1E1BD;
    border-radius: 10px; }
    .page-container.new-style .ui.form .ui.input input::placeholder, .page-container.new-style .ui.form .field textarea::placeholder, .page-container.new-style .ui.form input.autocomplete::placeholder {
      color: rgba(0, 0, 0, 0.4); }

.page-container.new-style.secondary {
  background-color: #3F3D3C;
  color: #fff; }
  .page-container.new-style.secondary .ui.form .field > label {
    color: #fff; }

.padded-checkboxes .ui.checkbox {
  margin-right: 20px; }

.splash .content-container {
  padding-top: 0; }

.content-container {
  min-height: 100%;
  background-color: #fafafa; }
  @media only screen and (max-width: 767px) {
    .content-container {
      padding-top: 0; } }
  .content-container .routes-container {
    padding-bottom: 80px !important; }
  .content-container .ui.grid > .row {
    padding-bottom: 0; }
  .content-container .trip-results-info {
    color: #555;
    font-size: 11pt;
    font-weight: 200; }
    .content-container .trip-results-info > div {
      padding-top: 6px; }
    .content-container .trip-results-info .left {
      padding-top: 20px; }

.routes-info-container {
  padding: 0 !important;
  background-color: #fff; }
  .routes-info-container:not(.new) {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); }
  .routes-info-container .route-row-container {
    border-bottom: 1px solid #ddd;
    cursor: pointer; }
    .routes-info-container .route-row-container.active {
      background-color: #f2f7f7; }
    .routes-info-container .route-row-container .route-info {
      height: 90px;
      width: 100%;
      overflow: hidden;
      padding-top: 8px; }
      .routes-info-container .route-row-container .route-info .overnight-icon {
        margin-left: 5px;
        color: #004b6b;
        opacity: 0.7; }
      .routes-info-container .route-row-container .route-info .padding {
        padding-left: 40px; }
      .routes-info-container .route-row-container .route-info .price {
        float: right;
        font-size: 14pt;
        margin-right: 4px; }
        .routes-info-container .route-row-container .route-info .price.incomplete, .routes-info-container .route-row-container .route-info .price.incomplete .euro-sign.cheapest {
          color: #bbb;
          font-weight: 300; }
        .routes-info-container .route-row-container .route-info .price .euro-sign {
          font-size: 13pt;
          font-weight: 300; }
          .routes-info-container .route-row-container .route-info .price .euro-sign.cheapest {
            color: green;
            font-weight: 600; }
        .routes-info-container .route-row-container .route-info .price i {
          font-size: 12pt;
          margin-left: 6px; }
        .routes-info-container .route-row-container .route-info .price.shadow {
          clear: right;
          margin-top: 6px; }
          .routes-info-container .route-row-container .route-info .price.shadow .ui.label {
            font-weight: normal !important;
            background-color: rgba(99, 44, 9, 0.59) !important;
            font-size: 9pt; }
      .routes-info-container .route-row-container .route-info i.duration {
        font-size: 10pt;
        margin-right: 2px; }
        .routes-info-container .route-row-container .route-info i.duration.shortest {
          color: green; }
      .routes-info-container .route-row-container .route-info a {
        float: right;
        clear: right;
        margin-top: 10px; }
      .routes-info-container .route-row-container .route-info .scores {
        margin-top: 6px; }
        .routes-info-container .route-row-container .route-info .scores .items {
          float: left; }
        .routes-info-container .route-row-container .route-info .scores .description {
          font-size: 9pt;
          color: #555;
          text-align: right;
          margin-top: 2px;
          line-height: 11pt; }
        .routes-info-container .route-row-container .route-info .scores .item {
          display: inline-block;
          text-align: center;
          margin-right: 20px; }
          .routes-info-container .route-row-container .route-info .scores .item .number {
            width: 34px;
            height: 34px;
            border-radius: 17px;
            color: #fff;
            line-height: 34px;
            font-size: 10pt;
            background-color: rgba(0, 75, 107, 0.8); }
          .routes-info-container .route-row-container .route-info .scores .item .title {
            font-size: 8pt;
            font-weight: 700;
            color: #777; }
      .routes-info-container .route-row-container .route-info.new .first-column {
        padding-left: 8px;
        padding-right: 4px; }
        @media only screen and (max-width: 767px) {
          .routes-info-container .route-row-container .route-info.new .first-column {
            padding-left: 5px; } }
        .routes-info-container .route-row-container .route-info.new .first-column .icons {
          float: left;
          opacity: 0.8;
          width: 70px; }
          @media only screen and (max-width: 991px) {
            .routes-info-container .route-row-container .route-info.new .first-column .icons {
              width: 50px; } }
          .routes-info-container .route-row-container .route-info.new .first-column .icons img.mode-icon.large {
            height: 60px;
            max-width: 60px; }
            @media only screen and (max-width: 991px) {
              .routes-info-container .route-row-container .route-info.new .first-column .icons img.mode-icon.large {
                height: 40px;
                max-width: 40px; } }
        .routes-info-container .route-row-container .route-info.new .first-column .carriers {
          margin-top: 8px; }
      .routes-info-container .route-row-container .route-info.new .duration {
        font-size: 13pt; }
      .routes-info-container .route-row-container .route-info.new .price {
        float: right;
        font-size: 14pt; }
        .routes-info-container .route-row-container .route-info.new .price.incomplete, .routes-info-container .route-row-container .route-info.new .price.incomplete .euro-sign.cheapest {
          color: #bbb;
          font-weight: 300; }
        .routes-info-container .route-row-container .route-info.new .price.estimate, .routes-info-container .route-row-container .route-info.new .price.estimate .euro-sign.cheapest {
          color: #888;
          font-weight: 300;
          display: inline-block;
          position: relative; }
          .routes-info-container .route-row-container .route-info.new .price.estimate:before, .routes-info-container .route-row-container .route-info.new .price.estimate .euro-sign.cheapest:before {
            content: "~~~~~~~~~~~~~~";
            font-size: 0.8em;
            font-weight: 500;
            font-family: Times New Roman, Serif;
            color: #ccc;
            width: 100%;
            position: absolute;
            top: 12px;
            left: -1px;
            overflow: hidden; }
        .routes-info-container .route-row-container .route-info.new .price .euro-sign {
          font-size: 13pt;
          font-weight: 300; }
          .routes-info-container .route-row-container .route-info.new .price .euro-sign.cheapest {
            color: green;
            font-weight: 600; }
        .routes-info-container .route-row-container .route-info.new .price i {
          font-size: 12pt;
          margin-left: 6px; }
        @media only screen and (max-width: 991px) {
          .routes-info-container .route-row-container .route-info.new .price {
            font-size: 11pt;
            float: none; }
            .routes-info-container .route-row-container .route-info.new .price i {
              font-size: 11pt; }
            .routes-info-container .route-row-container .route-info.new .price .euro-sign {
              font-size: 11pt; } }
      .routes-info-container .route-row-container .route-info.new i.duration {
        font-size: 13pt;
        margin-right: 2px; }
        .routes-info-container .route-row-container .route-info.new i.duration.shortest {
          color: green; }
        @media only screen and (max-width: 991px) {
          .routes-info-container .route-row-container .route-info.new i.duration {
            font-size: 9pt; } }
      .routes-info-container .route-row-container .route-info.new a {
        float: right;
        clear: right;
        margin-top: 10px; }
      .routes-info-container .route-row-container .route-info.new .scores {
        margin-top: 6px; }
        @media only screen and (max-width: 767px) {
          .routes-info-container .route-row-container .route-info.new .scores {
            margin-top: 0px; } }
        .routes-info-container .route-row-container .route-info.new .scores .items {
          float: left; }
        .routes-info-container .route-row-container .route-info.new .scores .description {
          font-size: 12pt;
          color: #555;
          text-align: right;
          margin-top: 2px;
          line-height: 11pt; }
        .routes-info-container .route-row-container .route-info.new .scores .item {
          display: inline-block;
          text-align: center;
          margin-right: 20px; }
          .routes-info-container .route-row-container .route-info.new .scores .item .number {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            line-height: 40px;
            color: #fff;
            font-size: 12pt;
            background-color: rgba(0, 75, 107, 0.8); }
            .routes-info-container .route-row-container .route-info.new .scores .item .number.small {
              font-size: 8pt; }
            .routes-info-container .route-row-container .route-info.new .scores .item .number i, .routes-info-container .route-row-container .route-info.new .scores .item .number .title {
              display: none; }
            @media only screen and (max-width: 991px) {
              .routes-info-container .route-row-container .route-info.new .scores .item .number {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                line-height: 32px;
                font-size: 8pt; } }
            @media only screen and (max-width: 767px) {
              .routes-info-container .route-row-container .route-info.new .scores .item .number {
                width: 28px;
                height: 28px;
                border-radius: 14px;
                line-height: 28px;
                font-size: 8pt; } }
          .routes-info-container .route-row-container .route-info.new .scores .item .title {
            font-size: 11pt;
            line-height: 20pt;
            font-weight: 700;
            color: #777; }
            @media only screen and (max-width: 767px) {
              .routes-info-container .route-row-container .route-info.new .scores .item .title {
                font-size: 9pt; } }
    .routes-info-container .route-row-container.active .hide-large, .routes-info-container .route-row-container:not(.small) .hide-large {
      display: none; }
    .routes-info-container .route-row-container.small:not(.active) .route-info {
      height: 40px; }
    .routes-info-container .route-row-container.small:not(.active) .hide-small {
      display: none; }
    .routes-info-container .route-row-container.small:not(.active) .new .first-column .icons img.mode-icon.large {
      margin-left: 10px;
      height: 24px;
      max-width: 24px; }
    .routes-info-container .route-row-container.small:not(.active) .new.scores {
      margin-top: 0px; }
      .routes-info-container .route-row-container.small:not(.active) .new.scores .items .item .number {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        line-height: 26px; }
        .routes-info-container .route-row-container.small:not(.active) .new.scores .items .item .number span {
          display: none; }
        .routes-info-container .route-row-container.small:not(.active) .new.scores .items .item .number i {
          line-height: 26px;
          display: inherit;
          font-size: 9pt;
          color: #fff;
          opacity: 0.9; }
        .routes-info-container .route-row-container.small:not(.active) .new.scores .items .item .number .title {
          display: inherit;
          font-size: 8pt;
          color: #fff;
          opacity: 0.9; }

.loading .hide-until-finished {
  display: none; }

.round-button {
  font-size: 10pt;
  line-height: normal;
  float: right;
  padding: 10px;
  background-color: #66cc33;
  color: #fff;
  border-radius: 25px;
  margin-right: 20px; }
  .round-button:hover {
    color: #fff;
    background-color: #3d7a1f; }

.social a {
  display: inline-block;
  margin: 5px; }
  .social a img {
    height: 28px;
    vertical-align: bottom; }

.social .calltoaction {
  color: #aaa;
  font-size: 10pt;
  font-style: italic; }

.social .facebook-container {
  display: inline-block;
  padding-top: 8px;
  margin-left: 5px; }

.home-container {
  margin: 40px 0;
  font-size: 12pt; }
  .home-container h1.ui.header.green-tickets-title {
    color: #004b6b;
    font-size: 40pt;
    margin-top: 24px;
    margin-bottom: 0; }
  .home-container h2.green-tickets-subtitle {
    font-size: 31.5pt;
    margin-top: -20px;
    font-weight: 100; }
  .home-container .with-highlighted {
    line-height: 2em; }
  .home-container .highlighted {
    font-weight: 500;
    background-color: #66cc33;
    color: #fff;
    box-shadow: 5px 0 0 #66cc33, -5px 0 0 #66cc33;
    padding: 5px 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
    margin-right: 5px; }
  .home-container .extra {
    font-weight: 100;
    margin-top: -15px;
    margin-bottom: 10px; }
  .home-container .circle {
    position: relative;
    display: inline-block;
    text-align: center;
    background-color: #f5f5f5;
    width: 400px;
    height: 400px;
    border-radius: 200px;
    margin: -120px 0 0 0;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05); }
    .home-container .circle.map {
      border: 2px solid #fff;
      background: url("resources/images/map.png") 40% 30%; }
    .home-container .circle .explanation {
      position: absolute;
      bottom: -25px;
      width: 100%;
      font-size: 9pt;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.05); }
      .home-container .circle .explanation h3 {
        color: #004b6b;
        margin-bottom: 5px; }
      .home-container .circle .explanation table {
        margin: 0 auto;
        width: 100%;
        line-height: 1.2em; }
        .home-container .circle .explanation table th {
          width: 33.3%; }
        .home-container .circle .explanation table td {
          vertical-align: top; }
        .home-container .circle .explanation table .soon {
          color: #555; }
  .home-container .trip-suggestion .image {
    height: 160px;
    overflow: hidden;
    border-radius: 20px; }

.modality-info-container {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  clear: both; }
  .modality-info-container .modality-info {
    padding: 10px;
    margin-top: 5px;
    cursor: pointer; }
    .modality-info-container .modality-info:hover {
      background-color: #f8f8f8; }
  .modality-info-container .modality-info:not(:last-child) .ui.grid .column {
    border-bottom: 5px solid #f0f0f0; }
  .modality-info-container .ui.grid .column {
    text-align: center !important;
    padding-bottom: 10px; }
    .modality-info-container .ui.grid .column .bar {
      height: 8px;
      border-radius: 8px;
      margin-bottom: 10px; }
    .modality-info-container .ui.grid .column img.mode-icon.large {
      height: auto;
      max-height: 60px; }
    .modality-info-container .ui.grid .column .icon {
      height: 24px;
      font-size: 18pt;
      line-height: 18pt;
      opacity: 0.3;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .modality-info-container .ui.grid .column .icon {
          display: none;
          margin-bottom: 0;
          font-size: 18pt;
          line-height: 18pt;
          height: 24px; } }
    .modality-info-container .ui.grid .column .subtitle {
      font-size: 10pt;
      margin-bottom: 5px;
      font-weight: bold; }
      @media only screen and (max-width: 767px) {
        .modality-info-container .ui.grid .column .subtitle {
          font-size: 8pt;
          line-height: 9pt;
          height: 20px; } }
    .modality-info-container .ui.grid .column .value {
      font-size: 16pt;
      opacity: 0.9;
      font-weight: 100; }
      @media only screen and (max-width: 767px) {
        .modality-info-container .ui.grid .column .value {
          font-size: 10pt;
          font-weight: 300; } }
      .modality-info-container .ui.grid .column .value .dash {
        font-weight: bold;
        opacity: 0.2; }

.routes-container {
  padding: 0 !important;
  position: relative;
  height: 100%;
  background-color: #fbfbfb;
  display: table;
  border-collapse: collapse; }
  .routes-container:not(.new) {
    overflow-x: scroll; }
  .routes-container.new {
    overflow-x: hidden; }
  .routes-container > svg {
    position: absolute;
    height: 100%;
    width: 100%; }
  .routes-container > svg rect {
    width: 100%;
    height: 100%; }
  .routes-container .route-row-container {
    display: table-row;
    border-bottom: 1px solid #ddd;
    width: 100%;
    cursor: pointer;
    overflow: hidden; }
    .routes-container .route-row-container.active {
      background-color: #f2f7f7; }
    .routes-container .route-row-container.small:not(.active) .route-row {
      height: 40px;
      padding: 0; }
      .routes-container .route-row-container.small:not(.active) .route-row .route-bar, .routes-container .route-row-container.small:not(.active) .route-row .bar, .routes-container .route-row-container.small:not(.active) .route-row .flight-background-fix {
        top: 15px;
        height: 10px;
        margin-top: 0; }
      .routes-container .route-row-container.small:not(.active) .route-row .part-start-time, .routes-container .route-row-container.small:not(.active) .route-row .part-end-time, .routes-container .route-row-container.small:not(.active) .route-row .origin, .routes-container .route-row-container.small:not(.active) .route-row .destination, .routes-container .route-row-container.small:not(.active) .route-row i {
        display: none; }
      .routes-container .route-row-container.small:not(.active) .route-row .start-time, .routes-container .route-row-container.small:not(.active) .route-row .end-time {
        font-size: 12pt;
        margin-top: 9px;
        padding-top: 0; }
  .routes-container .route-row {
    position: relative;
    padding: 10px 0;
    height: 90px;
    display: flex;
    white-space: nowrap;
    width: fit-content; }
    .routes-container .route-row.new {
      margin-left: 30px; }
    .routes-container .route-row .start-time, .routes-container .route-row .end-time {
      font-size: 16pt;
      font-weight: 500;
      padding: 10px;
      margin: 15px 0;
      width: 70px;
      color: #555; }
    .routes-container .route-row .start-time {
      position: absolute;
      left: -70px;
      text-align: right; }
    .routes-container .route-row .route-bar {
      position: absolute;
      background-color: #f0f0f0;
      top: 30px;
      height: 30px;
      border-radius: 15px;
      z-index: 0; }
    .routes-container .route-row .route-part {
      position: relative;
      height: 70px; }
      .routes-container .route-row .route-part .route-part-info {
        display: none;
        position: absolute;
        left: 5px;
        z-index: 99;
        background-color: #fff;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        border: 1px solid #eee;
        top: 55px;
        padding: 10px;
        font-size: 10pt; }
        .routes-container .route-row .route-part .route-part-info h4 {
          font-size: 10pt;
          margin: 0; }
        .routes-container .route-row .route-part .route-part-info th {
          text-align: right;
          padding-right: 4px; }
      .routes-container .route-row .route-part:hover .route-part-info {
        display: block; }
      .routes-container .route-row .route-part:hover .bar {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
      .routes-container .route-row .route-part .flight-background-fix {
        position: absolute;
        top: 20px;
        z-index: 0;
        width: 10px;
        height: 30px;
        background-color: #e1e1e1; }
        .routes-container .route-row .route-part .flight-background-fix.left {
          left: 0; }
        .routes-container .route-row .route-part .flight-background-fix.right {
          right: 0; }
      .routes-container .route-row .route-part .bar {
        position: absolute;
        z-index: 1;
        background-color: #bbb;
        height: 30px;
        width: 100%;
        margin-top: 20px;
        border-radius: 15px;
        overflow: hidden; }
        .routes-container .route-row .route-part .bar.estimation {
          background-color: #d5d5d5; }
        .routes-container .route-row .route-part .bar .icon {
          float: left;
          margin: 5px 10px;
          max-width: 100%; }
        .routes-container .route-row .route-part .bar .description {
          margin: 5px 10px;
          color: #444;
          font-weight: bold;
          font-size: 12px; }
        .routes-container .route-row .route-part .bar.WAITING {
          text-align: center; }
          .routes-container .route-row .route-part .bar.WAITING .icon {
            opacity: 0.4; }
        .routes-container .route-row .route-part .bar.FETCHING .icon {
          margin: 4px 10px; }
        .routes-container .route-row .route-part .bar.wait-before-flight {
          background-color: #e1e1e1;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
        .routes-container .route-row .route-part .bar.wait-after-flight {
          background-color: #e1e1e1;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .routes-container .route-row .route-part .part-start-time, .routes-container .route-row .route-part .part-end-time, .routes-container .route-row .route-part .origin, .routes-container .route-row .route-part .destination {
        position: absolute;
        font-size: 8pt;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        width: 50%;
        z-index: 9; }
        .routes-container .route-row .route-part .part-start-time.full, .routes-container .route-row .route-part .part-end-time.full, .routes-container .route-row .route-part .origin.full, .routes-container .route-row .route-part .destination.full {
          width: 100%; }
      .routes-container .route-row .route-part .part-start-time {
        top: 0;
        left: 0; }
      .routes-container .route-row .route-part .part-end-time {
        top: 0;
        right: 0;
        text-align: right; }
      .routes-container .route-row .route-part .origin {
        bottom: 0;
        left: 0; }
      .routes-container .route-row .route-part .destination {
        bottom: 0;
        right: 0;
        text-align: right; }

#map {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.route-info-modal-container .content.scrolling {
  width: 100%; }

.route-info-modal-container.ui.modal {
  border-radius: 10px;
  background-color: transparent;
  box-shadow: none; }

.route-info-modal-container .ui.grid > .row {
  padding: 0;
  margin: 0; }
  .route-info-modal-container .ui.grid > .row > .column {
    padding: 0; }

.route-info-modal {
  background-color: #fafafa;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  max-height: 100%;
  overflow-y: auto; }
  .route-info-modal .padding {
    padding: 10px; }
  .route-info-modal h1 {
    position: relative;
    background-color: #66cc33;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 10px;
    font-weight: 300;
    font-size: 18pt;
    margin-bottom: 0; }
    .route-info-modal h1 .share {
      display: block;
      float: right;
      text-align: center;
      color: #fff;
      cursor: pointer;
      margin-top: -6px;
      margin-bottom: -6px;
      font-size: 14pt; }
      .route-info-modal h1 .share:hover {
        color: rgba(255, 255, 255, 0.6); }
      .route-info-modal h1 .share div {
        font-size: 7pt;
        margin-top: -9px;
        font-weight: 500; }
  .route-info-modal .main-route-info-container {
    background-color: #fff;
    border-bottom: 1px solid #ddd; }
  .route-info-modal .leg {
    position: relative;
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    .route-info-modal .leg .part {
      margin: 0;
      border-radius: 0; }
    .route-info-modal .leg .booking-button-container {
      bottom: 10px;
      right: 0; }
  .route-info-modal .info .main-route-info {
    color: #004b6b;
    font-size: 13pt; }
    .route-info-modal .info .main-route-info strong {
      margin-right: 10px;
      font-weight: 500; }
  .route-info-modal .info .block {
    display: block;
    float: left;
    text-align: center;
    padding: 0 15px;
    border-radius: 15px;
    margin: 0 10px 10px 0;
    color: #fff;
    font-size: 9pt;
    background-color: rgba(0, 75, 107, 0.8);
    height: 25px;
    line-height: 25px; }
    .route-info-modal .info .block.wide {
      background-color: #004b6b;
      font-size: 10pt;
      height: 30px;
      line-height: 30px; }
    .route-info-modal .info .block.price.incomplete {
      background-color: #bbb; }
  .route-info-modal .route-feedback {
    text-align: center; }
    .route-info-modal .route-feedback h3 {
      color: #999;
      font-weight: 400; }
    .route-info-modal .route-feedback i {
      margin: 20px;
      font-size: 30pt;
      cursor: pointer;
      color: #999; }
      .route-info-modal .route-feedback i:hover {
        color: #777; }
  .route-info-modal .part {
    clear: left;
    display: block;
    width: 100%;
    background-color: #fff;
    font-size: 9pt;
    cursor: pointer; }
    .route-info-modal .part:not(.first) {
      border-top: none; }
    .route-info-modal .part:not(.last) {
      border-bottom: none; }
    .route-info-modal .part.waiting {
      cursor: default; }
    .route-info-modal .part:not(.leg-header):not(.leg-footer):not(.waiting):hover, .route-info-modal .part.active {
      background-color: #f2f7f7; }
    .route-info-modal .part .right {
      display: block;
      float: right;
      text-align: right;
      padding: 10px; }
      .route-info-modal .part .right .price {
        font-size: 16pt; }
        .route-info-modal .part .right .price.non-bookable {
          opacity: 0.4; }
      .route-info-modal .part .right .info {
        font-size: 8pt;
        opacity: 0.4;
        padding-top: 6px; }
    .route-info-modal .part .part-row {
      display: table-row;
      clear: both;
      padding: 4px; }
      .route-info-modal .part .part-row .carrier-image {
        position: absolute;
        right: 10px;
        margin: 10px 0;
        height: 30px; }
      .route-info-modal .part .part-row > div {
        display: table-cell;
        padding: 4px 8px; }
      .route-info-modal .part .part-row .left {
        width: 50px;
        overflow: hidden;
        color: #999;
        text-align: right; }
        .route-info-modal .part .part-row .left .ui.loader {
          color: #555 !important; }
        .route-info-modal .part .part-row .left .ui.loader:before {
          border-color: rgba(0, 0, 0, 0.15); }
        .route-info-modal .part .part-row .left .ui.loader:after {
          border-color: #555 transparent transparent; }
        .route-info-modal .part .part-row .left .mode-icon {
          opacity: 0.4;
          height: 24px;
          max-width: 24px;
          margin: 10px 0; }
          .route-info-modal .leg .part.waiting .left i {
            font-size: 14pt; }
      .route-info-modal .part .part-row .middle {
        vertical-align: middle; }
        .route-info-modal .leg .part.waiting .middle {
          color: #777;
          font-weight: 100; }
        .route-info-modal .part .part-row .middle .stops {
          color: #555; }
      .route-info-modal .part .part-row .line {
        width: 20px;
        position: relative; }
        .route-info-modal .part .part-row .line .inner-line {
          position: absolute;
          top: 0;
          left: 9px;
          right: 9px;
          bottom: 0; }
          .route-info-modal .leg .part-row.first .line .inner-line {
            top: 10px; }
          .route-info-modal .leg .part-row.last .line .inner-line {
            bottom: 10px; }
          .route-info-modal .leg .part:not(.first):not(.first-flight) .part-row.first .line .inner-line,
          .route-info-modal .leg .part:not(.last):not(.last-flight) .part-row.last .line .inner-line {
            top: 0;
            bottom: 0; }
        .route-info-modal .part .part-row .line .circle {
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          height: 10px;
          border-radius: 5px; }
  .route-info-modal .leg-header, .route-info-modal .leg-footer {
    clear: left;
    display: block;
    width: 100%;
    font-size: 10pt;
    text-align: right;
    cursor: default; }
    .route-info-modal .leg-header .agency, .route-info-modal .leg-footer .agency {
      display: inline-block;
      float: left;
      padding-left: 8px;
      font-size: 11pt; }
      .route-info-modal .leg-header .agency img, .route-info-modal .leg-footer .agency img {
        height: 25px;
        vertical-align: bottom;
        margin-right: 10px; }
    .route-info-modal .leg-header .ticket-options, .route-info-modal .leg-footer .ticket-options {
      float: left;
      padding-top: 12px;
      padding-left: 8px; }
      .route-info-modal .leg-header .ticket-options .class, .route-info-modal .leg-footer .ticket-options .class {
        padding: 2px 4px;
        margin: 0 4px;
        font-weight: bold;
        border: 1px solid #ccc;
        border-radius: 4px; }
    .route-info-modal .leg-header .ui.dropdown .menu > .item, .route-info-modal .leg-footer .ui.dropdown .menu > .item {
      padding-left: 4px !important;
      padding-right: 8px !important; }
    .route-info-modal .leg-header .ui.right.pointing.basic.label, .route-info-modal .leg-footer .ui.right.pointing.basic.label {
      padding: 3px; }
    .route-info-modal .leg-header .ui.button.no-rounded-corners, .route-info-modal .leg-footer .ui.button.no-rounded-corners {
      border-radius: 0;
      padding: 10px;
      font-size: 10pt; }
    .route-info-modal .leg-header .ui.button .ui.button.darker, .route-info-modal .leg-header .ui.button:hover .ui.button.darker, .route-info-modal .leg-footer .ui.button .ui.button.darker, .route-info-modal .leg-footer .ui.button:hover .ui.button.darker {
      background-color: #73767a;
      color: #fff;
      padding: 10px;
      font-size: 12pt; }
      .route-info-modal .leg-header .ui.button .ui.button.darker:hover, .route-info-modal .leg-header .ui.button:hover .ui.button.darker:hover, .route-info-modal .leg-footer .ui.button .ui.button.darker:hover, .route-info-modal .leg-footer .ui.button:hover .ui.button.darker:hover {
        background-color: #888b90; }
    .route-info-modal .leg-header.leg-header, .route-info-modal .leg-footer.leg-header {
      padding: 10px 12px 5px 0; }
    .route-info-modal .leg-header.leg-footer, .route-info-modal .leg-footer.leg-footer {
      padding: 5px 12px 0 5px;
      border-bottom: 1px solid rgba(0, 75, 107, 0.3); }
    .route-info-modal .leg-header .info, .route-info-modal .leg-footer .info {
      color: #666;
      font-weight: 100; }
    .route-info-modal .leg-header .price, .route-info-modal .leg-footer .price {
      font-size: 12pt;
      color: #004b6b;
      font-weight: 500; }
      .route-info-modal .leg-header .price i, .route-info-modal .leg-footer .price i {
        color: #ccc;
        font-size: 10pt;
        cursor: pointer; }
    .route-info-modal .leg-header a.round-button, .route-info-modal .leg-footer a.round-button {
      float: none;
      display: inline-block;
      margin: 0;
      padding: 10px 20px;
      font-weight: 500; }
  .route-info-modal h3 {
    margin-bottom: 6px; }
    .route-info-modal h3 i {
      width: 22px; }
    .route-info-modal h3 em {
      float: right;
      font-weight: 300;
      font-size: 80%; }
  .route-info-modal .card:hover {
    background-color: #fafafa; }
  .route-info-modal .card .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }

.mapContainer #main-map {
  z-index: 1;
  margin: 10px;
  display: flex;
  height: 800px;
  background-color: #fff; }

.roadmap .description {
  font-size: 10pt;
  color: #555; }

.roadmap-thanks {
  float: right;
  position: relative;
  width: 60px;
  text-align: right;
  opacity: 0;
  font-size: 10pt; }
  .roadmap-thanks .balloon {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #66cc33;
    position: absolute;
    bottom: 0;
    opacity: 1; }
    .roadmap-thanks .balloon.two {
      background: #7bdef5;
      left: 7px; }
    .roadmap-thanks .balloon.three {
      background: #004b6b;
      left: 18px; }
    .roadmap-thanks .balloon:before {
      content: "";
      height: 10px;
      position: absolute;
      left: 5px;
      top: 10px;
      border-right: 1px solid #ccc; }

.roadmap-thanks.active {
  animation: opacity-in-out 1s; }
  .roadmap-thanks.active .balloon {
    bottom: 30px;
    opacity: 0;
    transition: opacity 0.5s, bottom 0.5s ease-in; }
    .roadmap-thanks.active .balloon.two {
      transition-duration: 0.3s; }
    .roadmap-thanks.active .balloon.three {
      transition-duration: 0.7s; }

@keyframes opacity-in-out {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.loading-animation {
  background: #66cc33;
  padding: 40px;
  min-height: 120px; }
  .loading-animation .ui.card > .content {
    padding-top: 0;
    padding-bottom: 0;
    position: relative; }
    .loading-animation .ui.card > .content .loader {
      z-index: inherit; }
  .loading-animation img.mode-icon {
    opacity: 0.43; }
  .loading-animation .loader.waiting-search-interpretation {
    margin: 10px 0; }
  .loading-animation .search-interpretation {
    font-weight: 600;
    color: #666;
    font-size: 10pt;
    cursor: pointer; }
    .loading-animation .search-interpretation .back-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      text-align: left; }
    .loading-animation .search-interpretation:hover {
      background-color: #f7f7f7; }
      .loading-animation .search-interpretation:hover .back-icon {
        color: #777; }
    .loading-animation .search-interpretation .title {
      font-weight: 500;
      color: #000;
      font-size: 12pt; }
    .loading-animation .search-interpretation .date-constraint-info {
      font-weight: 400;
      margin-bottom: 2px;
      line-height: 12pt; }
    .loading-animation .search-interpretation i.arrow {
      width: 100%;
      font-size: 18pt;
      color: #ddd; }
  .loading-animation .ui.grid .row .column.search-interpretation {
    text-align: center;
    padding: 10px 10px; }
    .loading-animation .ui.grid .row .column.search-interpretation.loading {
      padding-top: 50px; }
  .loading-animation .ui.grid .result-row .result-column {
    text-align: center;
    padding: 10px 0;
    font-size: 10pt; }
    .loading-animation .ui.grid .result-row .result-column.flight-results .row {
      padding: 0; }
    .loading-animation .ui.grid .result-row .result-column.flight-results .column:last-child {
      padding-left: 0; }
    .loading-animation .ui.grid .result-row .result-column.flight-results .airports {
      text-align: left; }
      .loading-animation .ui.grid .result-row .result-column.flight-results .airports .airport-couple {
        font-size: 9pt;
        font-weight: bold;
        line-height: 1.5em;
        color: #aaa; }
        .loading-animation .ui.grid .result-row .result-column.flight-results .airports .airport-couple i {
          font-size: 6pt;
          display: inline;
          margin: 0 4px;
          line-height: inherit; }
        .loading-animation .ui.grid .result-row .result-column.flight-results .airports .airport-couple .loader {
          margin-left: 8px;
          margin-top: -2px; }
        .loading-animation .ui.grid .result-row .result-column.flight-results .airports .airport-couple .flights-count {
          display: inline-block !important;
          margin-left: 4px;
          font-weight: normal; }
    .loading-animation .ui.grid .result-row .result-column .note {
      font-size: 10pt;
      color: #aaa; }

.scale-question {
  text-align: center; }
  .scale-question .ui.buttons {
    margin: 0 10px; }

.donation-form {
  background: #f0f0f0;
  max-width: 1000px;
  margin: 0 auto;
  left: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); }
  .donation-form .donation-title {
    background: #66cc33;
    font-weight: bold;
    padding: 10px 20px;
    text-align: left;
    color: #fff;
    font-size: 16pt; }
    .donation-form .donation-title a {
      float: right;
      color: inherit;
      text-decoration: underline;
      font-weight: normal;
      font-style: italic;
      font-size: 12pt; }
      .donation-form .donation-title a:hover {
        text-decoration: none; }
  .donation-form .explanation {
    text-align: left;
    padding: 10px 20px;
    font-size: 10pt; }
  .donation-form .menu-holder {
    height: 82px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 10pt; }
    .donation-form .menu-holder .ui.dropdown, .donation-form .menu-holder .ui.dropdown.active {
      height: 40px;
      width: 100%;
      font-size: 11pt;
      padding: 5px 20px;
      border-radius: 10px 10px 0 10px;
      border: none;
      outline-width: 0; }
      .donation-form .menu-holder .ui.dropdown .text, .donation-form .menu-holder .ui.dropdown.active .text {
        line-height: 30px; }
      .donation-form .menu-holder .ui.dropdown .menu, .donation-form .menu-holder .ui.dropdown.active .menu {
        border: none;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2); }
  .donation-form .ui.input {
    height: 40px;
    width: 100%;
    font-size: 11pt;
    padding: 5px 20px;
    border-radius: 10px 10px 0 10px;
    border: none;
    outline-width: 0; }
    .donation-form .ui.input input, .donation-form .ui.input .label {
      border: none; }
    .donation-form .ui.input.labeled > .label {
      padding: 0;
      line-height: 30px; }
  .donation-form .donation-description {
    color: #aaa;
    font-size: 10pt; }
  .donation-form .donation-content {
    height: 200px;
    padding: 0 20px; }
    .donation-form .donation-content .content-flight .distance {
      text-align: center;
      margin-top: 25px; }
    .donation-form .donation-content .content-flight .menu .item {
      overflow: hidden;
      text-align: left;
      padding-left: 10px;
      cursor: pointer; }
    .donation-form .donation-content .content-flight .menu .item:hover {
      background: #fafafa; }
    .donation-form .donation-content .content-flight .ui.checkbox label {
      float: left; }
    .donation-form .donation-content .content-custom .ui.input {
      margin-top: 25px; }
    .donation-form .donation-content .content-donation .ui.input {
      margin-top: 25px; }
  .donation-form .donation-footer {
    background: linear-gradient(326deg, #004b6b 0%, #1d89b5 100%);
    height: 64px;
    line-height: 64px;
    color: #fff;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box; }
    .donation-form .donation-footer .co2-amount {
      width: 50%;
      float: left;
      line-height: 22px;
      padding-top: 10px; }
    .donation-form .donation-footer .pay-button-holder {
      width: 50%;
      float: left; }
  .donation-form .donation-airport {
    height: 40px;
    width: 100%;
    font-size: 11pt;
    padding: 5px 20px;
    border-radius: 10px 10px 0 10px;
    border: none;
    outline-width: 0; }
    .donation-form .donation-airport.donation-airport-to {
      margin-top: 20px; }
  .donation-form .donation-retour {
    margin-top: 20px; }

.compensation .compensation-menu {
  clear: both;
  width: 100%;
  display: block;
  margin-bottom: 25px; }
  .compensation .compensation-menu a {
    color: #000; }

.checkout-holder {
  background: url("resources/images/errol-337193-small.jpg") no-repeat top center;
  background-size: cover; }

.checkout-form .checkout-title {
  background: #66cc33;
  padding: 10px; }
  .checkout-form .checkout-title h2 {
    color: #fff; }

.checkout-form .vat, .checkout-form .vat label {
  color: #777 !important; }

.checkout-form .amount {
  font-size: 12pt; }
  .checkout-form .amount.total {
    font-weight: bold; }

.checkout-form .submit input {
  background-color: #004b6b;
  color: #fff; }

.gt-card {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #404040; }
  .gt-card h1 {
    color: #66cc33;
    font-weight: 500; }
  .gt-card img.top-image {
    position: absolute;
    top: 0;
    right: 20px;
    height: 150px; }
  .gt-card.inactive {
    opacity: 0.5; }

.homepage-header, .about-header, .roadmap-header, .contact-header, .travel-tips-header, .route-header, .blog-header, .stories-header {
  width: 100%;
  overflow: hidden; }
  .homepage-header.homepage-header .line-background, .about-header.homepage-header .line-background, .roadmap-header.homepage-header .line-background, .contact-header.homepage-header .line-background, .travel-tips-header.homepage-header .line-background, .route-header.homepage-header .line-background, .blog-header.homepage-header .line-background, .stories-header.homepage-header .line-background {
    min-height: 600px; }
    .homepage-header.homepage-header .line-background .line, .about-header.homepage-header .line-background .line, .roadmap-header.homepage-header .line-background .line, .contact-header.homepage-header .line-background .line, .travel-tips-header.homepage-header .line-background .line, .route-header.homepage-header .line-background .line, .blog-header.homepage-header .line-background .line, .stories-header.homepage-header .line-background .line {
      height: 400px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(-5deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.about-header .line-background, .about-header.about-header .line-background, .roadmap-header.about-header .line-background, .contact-header.about-header .line-background, .travel-tips-header.about-header .line-background, .route-header.about-header .line-background, .blog-header.about-header .line-background, .stories-header.about-header .line-background {
    background-image: url("resources/images/bus.jpg");
    min-height: 400px; }
    .homepage-header.about-header .line-background .line, .about-header.about-header .line-background .line, .roadmap-header.about-header .line-background .line, .contact-header.about-header .line-background .line, .travel-tips-header.about-header .line-background .line, .route-header.about-header .line-background .line, .blog-header.about-header .line-background .line, .stories-header.about-header .line-background .line {
      height: 300px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(3deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.blog-header .line-background, .about-header.blog-header .line-background, .roadmap-header.blog-header .line-background, .contact-header.blog-header .line-background, .travel-tips-header.blog-header .line-background, .route-header.blog-header .line-background, .blog-header.blog-header .line-background, .stories-header.blog-header .line-background {
    background: url("resources/images/DSC08091-smal.jpg") center;
    background-size: cover;
    min-height: 400px; }
    .homepage-header.blog-header .line-background .line, .about-header.blog-header .line-background .line, .roadmap-header.blog-header .line-background .line, .contact-header.blog-header .line-background .line, .travel-tips-header.blog-header .line-background .line, .route-header.blog-header .line-background .line, .blog-header.blog-header .line-background .line, .stories-header.blog-header .line-background .line {
      height: 280px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(-2deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.travel-tips-header .line-background, .about-header.travel-tips-header .line-background, .roadmap-header.travel-tips-header .line-background, .contact-header.travel-tips-header .line-background, .travel-tips-header.travel-tips-header .line-background, .route-header.travel-tips-header .line-background, .blog-header.travel-tips-header .line-background, .stories-header.travel-tips-header .line-background {
    background-image: url("resources/images/thomas-tucker-127734-copy.jpg");
    min-height: 300px; }
    .homepage-header.travel-tips-header .line-background .line, .about-header.travel-tips-header .line-background .line, .roadmap-header.travel-tips-header .line-background .line, .contact-header.travel-tips-header .line-background .line, .travel-tips-header.travel-tips-header .line-background .line, .route-header.travel-tips-header .line-background .line, .blog-header.travel-tips-header .line-background .line, .stories-header.travel-tips-header .line-background .line {
      height: 300px;
      width: 120%;
      margin-left: -10%;
      margin-top: -140px;
      position: absolute;
      transform: rotate(-3deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.roadmap-header .line-background, .about-header.roadmap-header .line-background, .roadmap-header.roadmap-header .line-background, .contact-header.roadmap-header .line-background, .travel-tips-header.roadmap-header .line-background, .route-header.roadmap-header .line-background, .blog-header.roadmap-header .line-background, .stories-header.roadmap-header .line-background {
    background-image: url("resources/images/gornergrat.jpg");
    background-position-y: -200px;
    min-height: 600px; }
    .homepage-header.roadmap-header .line-background .line, .about-header.roadmap-header .line-background .line, .roadmap-header.roadmap-header .line-background .line, .contact-header.roadmap-header .line-background .line, .travel-tips-header.roadmap-header .line-background .line, .route-header.roadmap-header .line-background .line, .blog-header.roadmap-header .line-background .line, .stories-header.roadmap-header .line-background .line {
      height: 250px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(-2deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.contact-header .line-background, .about-header.contact-header .line-background, .roadmap-header.contact-header .line-background, .contact-header.contact-header .line-background, .travel-tips-header.contact-header .line-background, .route-header.contact-header .line-background, .blog-header.contact-header .line-background, .stories-header.contact-header .line-background {
    background-image: url("resources/images/station.jpg");
    background-position-y: -350px;
    min-height: 300px; }
    .homepage-header.contact-header .line-background .line, .about-header.contact-header .line-background .line, .roadmap-header.contact-header .line-background .line, .contact-header.contact-header .line-background .line, .travel-tips-header.contact-header .line-background .line, .route-header.contact-header .line-background .line, .blog-header.contact-header .line-background .line, .stories-header.contact-header .line-background .line {
      height: 260px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(-1deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.route-header .line-background, .about-header.route-header .line-background, .roadmap-header.route-header .line-background, .contact-header.route-header .line-background, .travel-tips-header.route-header .line-background, .route-header.route-header .line-background, .blog-header.route-header .line-background, .stories-header.route-header .line-background {
    background-image: url("resources/images/konstantin-planinski-396545-small.jpg");
    background-position: bottom;
    min-height: 300px; }
    .homepage-header.route-header .line-background .line, .about-header.route-header .line-background .line, .roadmap-header.route-header .line-background .line, .contact-header.route-header .line-background .line, .travel-tips-header.route-header .line-background .line, .route-header.route-header .line-background .line, .blog-header.route-header .line-background .line, .stories-header.route-header .line-background .line {
      height: 260px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(1deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header.stories-header, .about-header.stories-header, .roadmap-header.stories-header, .contact-header.stories-header, .travel-tips-header.stories-header, .route-header.stories-header, .blog-header.stories-header, .stories-header.stories-header {
    min-height: 200px; }
    .homepage-header.stories-header .line, .about-header.stories-header .line, .roadmap-header.stories-header .line, .contact-header.stories-header .line, .travel-tips-header.stories-header .line, .route-header.stories-header .line, .blog-header.stories-header .line, .stories-header.stories-header .line {
      height: 260px;
      width: 120%;
      margin-left: -10%;
      margin-top: -100px;
      position: absolute;
      transform: rotate(-2deg);
      background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .homepage-header .line-background, .about-header .line-background, .roadmap-header .line-background, .contact-header .line-background, .travel-tips-header .line-background, .route-header .line-background, .blog-header .line-background, .stories-header .line-background {
    background-size: cover;
    position: relative; }
    .homepage-header .line-background p.white-text, .about-header .line-background p.white-text, .roadmap-header .line-background p.white-text, .contact-header .line-background p.white-text, .travel-tips-header .line-background p.white-text, .route-header .line-background p.white-text, .blog-header .line-background p.white-text, .stories-header .line-background p.white-text {
      color: #fff;
      line-height: 1.8em; }
  .homepage-header .offers-container, .about-header .offers-container, .roadmap-header .offers-container, .contact-header .offers-container, .travel-tips-header .offers-container, .route-header .offers-container, .blog-header .offers-container, .stories-header .offers-container {
    min-height: 300px; }
    .homepage-header .offers-container .offer, .homepage-header .offers-container .tip, .about-header .offers-container .offer, .about-header .offers-container .tip, .roadmap-header .offers-container .offer, .roadmap-header .offers-container .tip, .contact-header .offers-container .offer, .contact-header .offers-container .tip, .travel-tips-header .offers-container .offer, .travel-tips-header .offers-container .tip, .route-header .offers-container .offer, .route-header .offers-container .tip, .blog-header .offers-container .offer, .blog-header .offers-container .tip, .stories-header .offers-container .offer, .stories-header .offers-container .tip {
      display: inline-block;
      text-align: left !important;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 14px;
      padding-top: 8px;
      border-radius: 10px; }
      .homepage-header .offers-container .offer.tip h2, .homepage-header .offers-container .tip.tip h2, .about-header .offers-container .offer.tip h2, .about-header .offers-container .tip.tip h2, .roadmap-header .offers-container .offer.tip h2, .roadmap-header .offers-container .tip.tip h2, .contact-header .offers-container .offer.tip h2, .contact-header .offers-container .tip.tip h2, .travel-tips-header .offers-container .offer.tip h2, .travel-tips-header .offers-container .tip.tip h2, .route-header .offers-container .offer.tip h2, .route-header .offers-container .tip.tip h2, .blog-header .offers-container .offer.tip h2, .blog-header .offers-container .tip.tip h2, .stories-header .offers-container .offer.tip h2, .stories-header .offers-container .tip.tip h2 {
        font-size: 14pt;
        margin: 0; }
      .homepage-header .offers-container .offer.tip .image-container, .homepage-header .offers-container .tip.tip .image-container, .about-header .offers-container .offer.tip .image-container, .about-header .offers-container .tip.tip .image-container, .roadmap-header .offers-container .offer.tip .image-container, .roadmap-header .offers-container .tip.tip .image-container, .contact-header .offers-container .offer.tip .image-container, .contact-header .offers-container .tip.tip .image-container, .travel-tips-header .offers-container .offer.tip .image-container, .travel-tips-header .offers-container .tip.tip .image-container, .route-header .offers-container .offer.tip .image-container, .route-header .offers-container .tip.tip .image-container, .blog-header .offers-container .offer.tip .image-container, .blog-header .offers-container .tip.tip .image-container, .stories-header .offers-container .offer.tip .image-container, .stories-header .offers-container .tip.tip .image-container {
        width: 100%;
        max-height: 120px;
        overflow: hidden;
        margin-bottom: 6px; }
        .homepage-header .offers-container .offer.tip .image-container img, .homepage-header .offers-container .tip.tip .image-container img, .about-header .offers-container .offer.tip .image-container img, .about-header .offers-container .tip.tip .image-container img, .roadmap-header .offers-container .offer.tip .image-container img, .roadmap-header .offers-container .tip.tip .image-container img, .contact-header .offers-container .offer.tip .image-container img, .contact-header .offers-container .tip.tip .image-container img, .travel-tips-header .offers-container .offer.tip .image-container img, .travel-tips-header .offers-container .tip.tip .image-container img, .route-header .offers-container .offer.tip .image-container img, .route-header .offers-container .tip.tip .image-container img, .blog-header .offers-container .offer.tip .image-container img, .blog-header .offers-container .tip.tip .image-container img, .stories-header .offers-container .offer.tip .image-container img, .stories-header .offers-container .tip.tip .image-container img {
          width: 100%; }

.co2-compensation .title {
  background-color: #eee;
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .co2-compensation .title .faq {
    float: right;
    margin: 8px;
    font-size: 16pt;
    cursor: pointer; }
  .co2-compensation .title h1 {
    margin: 0;
    padding: 12px 25px;
    color: #3f3f3f;
    font-size: 16pt; }

.co2-compensation .card-footer {
  padding: 10px 25px 10px 270px;
  border-top: 1px solid #eee;
  min-height: 60px; }
  .co2-compensation .card-footer .co2-amount {
    font-weight: 700;
    color: #111;
    line-height: 35px; }
  .co2-compensation .card-footer .pay-button-holder {
    float: right; }

.co2-compensation .menu {
  color: #404040;
  font-weight: 400;
  font-size: 12pt;
  border-right: 1px solid #eee; }
  .co2-compensation .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 2.2em; }
    .co2-compensation .menu ul li {
      position: relative;
      margin: 0;
      padding: 0;
      cursor: pointer; }
      .co2-compensation .menu ul li:before {
        content: "";
        position: absolute;
        margin-left: -16px;
        width: 8px;
        height: 100%;
        background-color: #eee; }
      .co2-compensation .menu ul li.active, .co2-compensation .menu ul li:hover {
        color: #66cc33; }
      .co2-compensation .menu ul li.active:before {
        background-color: #66cc33; }
  .co2-compensation .menu .in-menu-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10pt;
    color: #ddd;
    letter-spacing: 0.8px;
    margin: 5px 0; }

.co2-compensation .page {
  padding-top: 0; }
  .co2-compensation .page h1 {
    margin-top: 0; }
  .co2-compensation .page p {
    position: relative; }
  .co2-compensation .page.about .list {
    counter-reset: my-counter; }
    .co2-compensation .page.about .list dt {
      display: block;
      position: relative;
      padding: 6px 6px 6px 30px;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      margin: 15px 5px;
      background: #fafafa;
      width: 30%;
      float: left; }
    .co2-compensation .page.about .list dt:before {
      content: counter(my-counter);
      counter-increment: my-counter;
      position: absolute;
      top: 5px;
      left: -2px;
      color: #66cc33;
      font-size: 37pt;
      font-weight: 100; }
  .co2-compensation .page .autocomplete-holder {
    position: relative;
    font-size: 10pt; }
    .co2-compensation .page .autocomplete-holder > div {
      width: 100%; }
      .co2-compensation .page .autocomplete-holder > div > div {
        position: absolute !important;
        top: auto !important;
        left: auto !important;
        max-height: none !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        z-index: 10001; }
  .co2-compensation .page .ui.right.labeled.input input, .co2-compensation .page .ui.input input {
    border-color: #66cc33; }
  .co2-compensation .page input {
    height: 40px;
    width: 100%;
    border-radius: 10px 10px 0 10px;
    border: 1px solid #66cc33;
    padding: 5px 20px;
    font-size: 11pt; }
  .co2-compensation .page .ui.basic.label {
    border-bottom-right-radius: 0;
    border-color: #66cc33;
    color: #777; }
  .co2-compensation .page .field-holders-container {
    padding-top: 25px; }
  .co2-compensation .page .field-holder {
    position: relative; }
    .co2-compensation .page .field-holder .label {
      position: absolute;
      top: -22px;
      font-size: 10pt;
      font-weight: 700; }
    .co2-compensation .page .field-holder .hide {
      position: absolute;
      top: -22px;
      right: 0;
      color: #666;
      cursor: pointer; }
  .co2-compensation .page .pax-holder {
    margin-left: 20px;
    width: 200px; }
  .co2-compensation .page .distance {
    font-style: italic; }
  .co2-compensation .page .flights-arrow {
    margin: 10px; }
  .co2-compensation .page .ui.checkbox {
    text-align: center;
    margin: 10px 10px; }
  .co2-compensation .page .ui.button {
    margin-left: 10px; }
  .co2-compensation .page .buttons .ui.button {
    margin-left: 0; }
  .co2-compensation .page .content-holiday {
    display: block; }
    .co2-compensation .page .content-holiday .card {
      padding: 10px;
      margin-bottom: 15px; }
      .co2-compensation .page .content-holiday .card:hover {
        background: #fafafa;
        cursor: pointer; }
      .co2-compensation .page .content-holiday .card.active {
        box-shadow: 0 1px 3px 0 #66cc33, 0 0 0 1px #66cc33; }
      .co2-compensation .page .content-holiday .card img {
        height: 30px; }
      .co2-compensation .page .content-holiday .card .content {
        padding: 2px; }
        .co2-compensation .page .content-holiday .card .content .header {
          font-size: 10pt;
          padding-top: 6px;
          flex: 1; }
        .co2-compensation .page .content-holiday .card .content .meta {
          font-size: 10pt; }
    .co2-compensation .page .content-holiday .ui.buttons {
      margin-bottom: -25px; }
      .co2-compensation .page .content-holiday .ui.buttons button {
        font-size: 8pt;
        padding: 10px 12px; }

h1.large-main-title {
  position: relative;
  text-align: center;
  margin: 20px 0 0;
  font-size: 32pt;
  color: #ccc;
  flex: 1;
  font-weight: 300; }
  h1.large-main-title span {
    position: relative;
    background-color: #fbfbfb;
    z-index: 2;
    padding: 0 20px; }
  h1.large-main-title:before {
    content: "";
    display: block;
    position: relative;
    top: 32px;
    left: 0;
    background-color: #ccc;
    height: 1px;
    width: 100%;
    z-index: 1; }
  h1.large-main-title.dark {
    color: rgba(255, 255, 255, 0.8); }
    h1.large-main-title.dark span {
      background-color: #004b6b; }
    h1.large-main-title.dark:before {
      background-color: rgba(255, 255, 255, 0.8); }

.main-intro-container {
  padding-top: 30px;
  text-align: right;
  color: #fff; }
  .main-intro-container h2 {
    color: #fff;
    font-weight: 400;
    font-size: 13pt;
    font-style: italic; }
  .main-intro-container .ui.button {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #fff;
    color: #fff;
    margin-bottom: 14px;
    border-bottom-right-radius: 0; }

.content-questions-container {
  padding-top: 100px;
  text-align: left; }
  .content-questions-container h1 {
    margin-bottom: 0; }
  .content-questions-container h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 15pt;
    font-style: italic; }
  .content-questions-container .ui.button.basic {
    border-radius: 10px;
    margin-bottom: 14px;
    border-bottom-right-radius: 0; }

.navigation-items-container {
  margin-top: 30px; }
  .navigation-items-container .item {
    margin: 0 auto;
    max-width: 300px !important;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border: 1px solid #fff;
    cursor: pointer; }
    .navigation-items-container .item:hover {
      background-color: #efefef; }
    .navigation-items-container .item .image {
      height: 100px;
      width: 100%;
      overflow: hidden;
      opacity: 0.8; }
      .navigation-items-container .item .image .img {
        background-size: cover;
        background-position: center center;
        height: 100px; }
        .navigation-items-container .item .image .img.one {
          background-image: url("resources/images/screenshot-2.jpg"); }
        .navigation-items-container .item .image .img.two {
          background-image: url("resources/images/map-2.png"); }
        .navigation-items-container .item .image .img.three {
          background-image: url("resources/images/infographic-graph.png"); }
        .navigation-items-container .item .image .img.four {
          background-image: url("resources/images/vlcsnap-2018-11-06-11h15m26s032.png");
          background-position-y: 0; }
    .navigation-items-container .item h2 {
      font-size: 12pt;
      font-weight: 500;
      margin: 0;
      padding: 0; }
    .navigation-items-container .item .content {
      padding: 8px; }

.homepage-header-newsletter {
  text-align: center;
  margin: 10px 0; }
  .homepage-header-newsletter label {
    float: left;
    font-size: 19.5pt;
    margin-right: 20px;
    color: #66cc33;
    font-weight: 700; }
    .homepage-header-newsletter label small {
      font-size: 10pt;
      color: #004b6b;
      font-weight: 800; }

#rail {
  background-color: #004b6b;
  width: 100%;
  margin-bottom: 20px; }
  #rail .window-card {
    position: relative;
    margin: 20px 30px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    flex: 1;
    min-height: 240px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    overflow: hidden; }
    #rail .window-card .line {
      position: absolute;
      top: -40px;
      left: -10px;
      height: 160px;
      width: 120%;
      background-color: #fff;
      transform: rotate(-8deg); }
    #rail .window-card .padding {
      position: relative;
      font-size: 10pt; }
    #rail .window-card h2 {
      font-size: 14pt; }

.main-background-line-container {
  position: absolute;
  width: 100%;
  height: 800px;
  overflow: hidden; }

.main-background-line {
  height: 250px;
  width: 120%;
  margin-left: -10%;
  margin-top: 240px;
  position: absolute;
  transform: rotate(-5deg);
  background: linear-gradient(to right, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%), linear-gradient(to bottom, #66cc33 0%, rgba(102, 204, 51, 0.5) 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .main-background-line.planner {
    margin-top: 170px;
    background: linear-gradient(to right, #004b6b 0%, rgba(0, 75, 107, 0.5) 100%), linear-gradient(to bottom, #004b6b 0%, rgba(0, 75, 107, 0.5) 100%); }

.main-explanation {
  font-size: 12pt;
  color: #373737; }
  .main-explanation p {
    text-align: center; }

.main-screenshots {
  margin-top: 80px; }
  .main-screenshots img {
    border: 8px solid #004b6b; }

.route-info-page .photos-container img {
  border-radius: 5px;
  width: 140px;
  max-height: 110px;
  margin-right: 5px; }

.interactive-map-container {
  position: relative; }
  .interactive-map-container .list-container-header {
    padding: 0 !important; }
  .interactive-map-container .type-filter-header .ui.button {
    position: relative; }
    .interactive-map-container .type-filter-header .ui.button .color {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px; }
  .interactive-map-container .list-container {
    height: 600px;
    overflow-y: auto;
    padding: 0 !important; }
    .interactive-map-container .list-container .line {
      cursor: pointer; }
      .interactive-map-container .list-container .line .photos-container img {
        border-radius: 5px;
        width: 80px;
        max-height: 60px;
        margin-right: 3px; }
      .interactive-map-container .list-container .line h2 {
        font-size: 12pt;
        margin: 0;
        margin-bottom: 4px; }
  .interactive-map-container #interactive-map {
    width: 100%;
    height: 700px;
    background-color: #eee; }
  .interactive-map-container .legend {
    text-align: center; }
    .interactive-map-container .legend .legend-item {
      display: inline-block;
      margin: 5px; }
    .interactive-map-container .legend .legend-color {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px; }
  .interactive-map-container .explanation {
    margin-top: 20px;
    color: #ccc; }
    .interactive-map-container .explanation a {
      color: #555; }
  .interactive-map-container .floating-legend {
    width: 270px;
    height: 150px;
    background: #fff;
    bottom: 100px;
    z-index: 1;
    position: absolute;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    line-height: 40px;
    padding: 10px 25px; }
    .interactive-map-container .floating-legend .icon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
      .interactive-map-container .floating-legend .icon.green {
        background: rgba(102, 204, 51, 0.5);
        border: 1px solid #66cc33; }
      .interactive-map-container .floating-legend .icon.blue {
        background: rgba(0, 75, 107, 0.5);
        border: 1px solid #004b6b; }

.interactive-map-full-page .interactive-map-container #interactive-map, .interactive-map-full-page .interactive-map-container .list-container {
  height: 800px; }

.interactive-map-div-container {
  position: relative; }
  .interactive-map-div-container .list-container {
    position: absolute;
    top: 40px;
    left: 40px;
    bottom: 40px;
    width: 20%;
    min-width: 300px; }

.team h3 {
  margin: 4px; }

.team .quote {
  color: #004b6b; }

.team .study {
  font-size: 10pt;
  color: #666; }

.tag-filter-container .ui.button {
  margin: 6px;
  min-width: 140px;
  text-align: left; }

.travel-tips-container .column {
  padding: 10px !important; }

.travel-tips-container .gt-card {
  width: auto;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 10px;
  font-size: 10pt;
  text-align: justify; }
  .travel-tips-container .gt-card .image-container {
    width: 100%;
    background-color: #eee;
    height: 150px;
    overflow: hidden;
    margin-top: 10px;
    text-align: center; }
    .travel-tips-container .gt-card .image-container img {
      max-width: 100%;
      max-height: 150%; }
  .travel-tips-container .gt-card .html-container {
    text-align: center;
    margin-top: 10px; }
  .travel-tips-container .gt-card h2 {
    font-size: 16pt;
    margin: 0;
    margin-bottom: 4px;
    text-align: left; }
  .travel-tips-container .gt-card h5 {
    margin: 0;
    margin-bottom: 6px; }
  .travel-tips-container .gt-card .tags {
    margin-top: 6px;
    margin-bottom: 0;
    opacity: 0.6; }
  .travel-tips-container .gt-card .date {
    font-weight: 700;
    padding: 4px 0;
    font-size: 9pt; }

.travel-tips-container.simple .column {
  max-width: 200px; }

.travel-tips-container.simple .gt-card h2 {
  font-size: 12pt; }

.travel-tips-container.simple .gt-card h5 {
  font-size: 9pt;
  text-align: left; }

.travel-tips-container.simple .gt-card .image-container {
  height: 100px; }

.about-travel-tips-container {
  width: 100% !important; }

#interactive-map {
  width: 100%;
  height: 600px;
  background-color: #eee; }

.gt-card {
  margin: 50px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.infographic {
  font-family: 'Open Sans', sans-serif !important;
  overflow: hidden; }
  .infographic .line-background {
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: -300px; }
    .infographic .line-background .line {
      height: 300px;
      width: 120%;
      position: relative;
      margin-left: -10%;
      margin-top: -100px;
      transform: rotate(-7deg);
      background-color: #66cc33;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
    .infographic .line-background img {
      width: 300px;
      position: absolute;
      right: 50px;
      bottom: 25px; }
  .infographic .header {
    position: relative;
    text-align: center;
    margin-bottom: 150px; }
    .infographic .header h1 {
      font-size: 36pt;
      color: #fff;
      font-weight: 700;
      margin: 25px 0 0 0;
      font-family: 'Open Sans', sans-serif;
      background: none;
      padding: 0;
      line-height: 1.3em; }
    .infographic .header h2 {
      margin: 10px 0;
      font-size: 22pt;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;
      font-weight: 100;
      letter-spacing: 2px; }
    .infographic .header h3 {
      margin: 0;
      color: #fff;
      font-weight: 100;
      font-size: 12pt;
      font-family: 'Open Sans', sans-serif;
      opacity: .5; }
    .infographic .header .sharing {
      position: absolute;
      width: 150px;
      top: 25px;
      right: 25px;
      color: #fff; }
      .infographic .header .sharing i {
        padding: 0 10px;
        font-size: 18pt;
        cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .infographic .header h1 {
        font-size: 19pt; }
      .infographic .header h2 {
        font-size: 15pt; }
      .infographic .header .sharing {
        position: inherit; } }
  .infographic h1:not(.header-h1) {
    background: #66cc33;
    color: #fff;
    text-align: center;
    padding: 25px;
    font-size: 18pt;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
    .infographic h1:not(.header-h1).flush {
      margin-top: 0; }
    .infographic h1:not(.header-h1) strong {
      font-weight: 800; }
    .infographic h1:not(.header-h1) a {
      color: #fff;
      text-decoration: underline; }
      .infographic h1:not(.header-h1) a:hover {
        text-decoration: none; }
      .infographic h1:not(.header-h1) a.ref {
        text-decoration: none; }
  .infographic h2 {
    text-align: center;
    color: #000;
    font-size: 14pt;
    font-weight: 700;
    position: relative;
    font-family: 'Open Sans', sans-serif; }
  .infographic .ref {
    color: #004b6b;
    font-weight: 700;
    vertical-align: super;
    font-size: 10pt; }
  .infographic img {
    width: 100%;
    vertical-align: middle; }
  .infographic .padding {
    padding: 10px 50px; }
  .infographic .small-padding {
    padding: 45px 25px 0 25px; }
  .infographic .flush {
    margin-top: -14px; }
  .infographic .columns {
    display: grid; }
    .infographic .columns.two-columns {
      grid-template-areas: "column column";
      grid-template-columns: 1fr 1fr; }
  .infographic .footer {
    padding: 25px;
    background: #004b6b;
    font-weight: bold;
    line-height: 2em; }
    .infographic .footer .ref-details {
      color: rgba(255, 255, 255, 0.5);
      display: block;
      font-weight: normal; }
      .infographic .footer .ref-details:hover {
        text-decoration: none; }
      .infographic .footer .ref-details .number {
        font-weight: bold;
        color: #fff; }
  .infographic .inset {
    width: 80%;
    margin: 20px auto;
    line-height: 2em;
    font-size: 12pt; }
  .infographic .center {
    text-align: center; }
  .infographic .legend {
    line-height: 50px; }
    .infographic .legend .legend-item {
      width: 100px;
      height: 10px;
      float: left;
      clear: both;
      margin: 20px 25px 20px 0;
      border-radius: 2px;
      background: #9B9B9B; }
      .infographic .legend .legend-item.blue {
        background: #004b6b; }
      .infographic .legend .legend-item.green {
        background: #66cc33; }
    .infographic .legend.legend-square {
      line-height: 30px;
      margin-top: 20px; }
      .infographic .legend.legend-square .legend-item {
        width: 30px;
        height: 30px;
        float: inherit;
        display: inline-block;
        margin: -8px 10px; }
  .infographic .bar-graph {
    display: grid;
    grid-template-areas: "value bar";
    grid-template-columns: 50px 1fr;
    grid-gap: 20px; }
    .infographic .bar-graph .value {
      text-align: right; }
      .infographic .bar-graph .value.unit {
        font-weight: 700;
        font-size: 10pt;
        color: #ccc;
        margin-top: -35px; }
    .infographic .bar-graph .bar {
      background: #9B9B9B;
      height: 30px;
      color: #fff;
      line-height: 30px;
      padding-left: 5px; }
      .infographic .bar-graph .bar.blue {
        background: #004b6b; }
      .infographic .bar-graph .bar.green {
        background: #66cc33; }
      .infographic .bar-graph .bar.savings {
        background: none;
        color: #ccc;
        margin-top: -35px;
        border-top: 1px solid #ccc;
        text-align: center;
        font-size: 10pt;
        font-weight: 600; }
        .infographic .bar-graph .bar.savings div {
          margin-top: -15px;
          border-right: 1px solid #ccc; }
          .infographic .bar-graph .bar.savings div span {
            background: #fff;
            padding: 0 5px; }

.infographic-emissions .country-description h2 {
  font-size: 12pt;
  margin-top: 20px; }

.infographic-emissions .country-description p {
  padding-top: 0;
  padding-bottom: 0;
  color: #ccc;
  font-size: 12pt; }

.infographic-emissions .country-description small {
  font-size: 8pt; }

.mapMakerContainer .main-map {
  width: 100%;
  height: 500px; }

.interrail-pricing-table .ages {
  font-weight: normal;
  margin-left: 10px; }

.co2-examples-table .clickable {
  cursor: pointer;
  text-decoration: underline; }
  .co2-examples-table .clickable:hover {
    background-color: #eee; }

.clickable {
  cursor: pointer; }

.autocomplete-holder {
  position: relative;
  font-size: 10pt; }
  .autocomplete-holder > div {
    width: 100%; }
    .autocomplete-holder > div > div {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
      max-height: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      z-index: 10001; }

.co2-comparison-container .co2-comparison-unit {
  display: inline-block;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px; }
  .co2-comparison-container .co2-comparison-unit .header {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .co2-comparison-container .co2-comparison-unit .title {
    margin-bottom: 0;
    z-index: 2; }
  .co2-comparison-container .co2-comparison-unit .icon {
    display: inline-block;
    margin: 6px;
    height: 40px;
    width: 40px;
    opacity: 0.9;
    z-index: 1;
    text-align: center;
    position: relative; }
    .co2-comparison-container .co2-comparison-unit .icon img {
      max-width: 100%;
      max-height: 100%; }
    .co2-comparison-container .co2-comparison-unit .icon .bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      opacity: 0.9; }
  .co2-comparison-container .co2-comparison-unit .factor {
    clear: both; }
    .co2-comparison-container .co2-comparison-unit .factor .source {
      color: #555; }

.pushable .sidebar {
  padding-top: 60px; }

.pushable .ui.icon.menu .item {
  text-align: left; }

.planner-matrix .row {
  text-align: center;
  border-bottom: 1px solid #ccc; }
  .planner-matrix .row.header {
    font-weight: bold; }
    .planner-matrix .row.header .column {
      padding: 10px; }
